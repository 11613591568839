// Initialize Firebase
export const config = {  
  apiKey: "AIzaSyBQUK3AUhHzf05sWk15hcnxBvlbIt2EiBA",
  authDomain: "dooree-dev.firebaseapp.com",
  databaseURL: "https://dooree-dev.firebaseio.com",
  projectId: "dooree-dev",
  storageBucket: "dooree-dev.appspot.com",
  messagingSenderId: "414096209792",
  appId: "1:414096209792:web:f67b5e75e20ea4c4e53365",
  measurementId: "G-NC3XKQZ1EY"
}
  