import {
  ADD_BRANCH, BRANCH_FILE_UPLOAD_STATUS, BRANCH_QUEUE_VALID_STATUS, BRANCH_SERVICE_VALID_STATUS, BRANCH_AGENT_VALID_STATUS, BRANCH_COUNTER_VALID_STATUS
} from '../../actions/types';
  
  const initState = {
    branch: {
      branch: [],
      pageNo: 0
    },
    isBranchValid:{
      isBranchService: true,
      isBranchQueue: true,
      isBranchAgent: true,
      isBranchCounter: true
    },
    fileUploadStatus:''
  };
  
  const branch = (state = initState, action) => {
    switch (action.type) {
     
      case ADD_BRANCH: {
        return { ...state, branch: action.payload }
      }
      case BRANCH_SERVICE_VALID_STATUS: {
        return { ...state, isBranchValid: {...state.isBranchValid,isBranchService:action.payload} }
      }    
      case BRANCH_QUEUE_VALID_STATUS: {
        return { ...state, isBranchValid: {...state.isBranchValid,isBranchQueue:action.payload} }
      }    
      case BRANCH_AGENT_VALID_STATUS: {
        return { ...state, isBranchValid: {...state.isBranchValid,isBranchAgent:action.payload} }
      }    
      case BRANCH_COUNTER_VALID_STATUS: {
        return { ...state, isBranchValid: {...state.isBranchValid,isBranchCounter:action.payload} }
      }    
      case BRANCH_FILE_UPLOAD_STATUS: {
        return { ...state, fileUploadStatus: action.payload }
      }    
      default: {
        return {...state}
      }
    }
  }
  
  export default branch;
  