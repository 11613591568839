import {
  SET_DASHBOARD_INVALID_BRANCH,
  TOTAL_TICKETS,
  TOTAL_SERVE,
  TOTAL_CANCEL,
  TOTAL_NOSHOW,
  TOTAL_NOTPROCESS,
  TICKET_PER_SERVICE,
  TICKET_PER_BRANCH,
  TICKET_PER_AGENT,
  SERVICE_AVG_RATING,
  BRANCH_AVG_RATING,
  AGENT_AVG_RATING,
  BRANCH_PERFORMANCE,
  TICKET_PER_DAY,
  AVG_CUSTOMER_RATING,
  TOTAL_REVIEW,
  MOST_USE_SERVICE,
  AVG_TICKET_PER_AGENT,
  GRAND_AVG_PER_AGENT,
  GRAND_AVG_PER_BRANCH,
  IS_AVG_SERVING_TIME_SHOW,
  AVG_SERVE_TIME,
  ADD_QUEUE,
  GET_QUEUE_SERVICE,
  ADD_SERVICE,
  ADD_USER,
  ADD_BRANCH,
  SET_SETTING,
  SET_CURRENT_USER,
  SET_PUBLIC_KEY,
  TICKETS_BY_ORIGIN,
  REBOOKTICKETS,
  TOTAL_APPOINTMENT,
  APPOINTMENT_NOPROCESS,
  APPOINTMENT_NOSHOW,
  APPOINTMENT_CANCELLEDTICKET,
  APPOINTMENT_PROCESSING,
  APPOINTMENT_SCHEDULED,
  APPOINTMENT_COMPLETED,
  APPOINTMENT_PER_DAY,
  ESERVICE_NUMBEROFREQUESTS,
  ESERVICE_SUBMITTED,
  ESERVICE_PROCESSING,
  ESERVICE_COMPLETED,
  ESERVICE_CANCELLED,
  SET_ESERVICE_TRAIL,
  SET_ESERVICE_LOADER,
  APPOINTMENT_PEAK_HOURS,
  DONATION_NUMBEROFREQUESTS,
  DONATION_COMPLETED,
  DONATION_PROCESSING,
  DONATION_SUBMITTED,
  DONATION_CANCELLED,
  DONATION_ENGAGEMENT,
} from "../types";
import { api } from "../../../api/api";
import toast from "react-hot-toast";
import { GlobalVariable } from "../../../util/index";
import moment from "moment";
import { setDashboardLoaderAction, setLoaderAction } from "../loader/index";
import { getServiceAction } from "../service/index";
import { getUserAction } from "../user/index";
import { QueueApi } from "../../../api/queueApi";
import { store } from "../../storeConfig/store";
import {
  getProfile,
  setAuthTokenAction,
  setIsLoginAction,
  setLoginUserAction,
} from "../auth";
import i18n, { getTranslation } from "../../../i18n/i18n";
import { getStartTimeAndEndTime, getproperData } from "../../../util/helper";

const api_status = GlobalVariable.apiResponseStatus;

/**
 @name  getDashboardCounts
 @file dashboard.js
 @description This method is used for get dashboard ticket stats
 */
export const getDashboardCounts = (data) => {
  return async (dispatch, store) => {
    dispatch(setDashboardLoaderAction(true));
    let dashboardCount1api = [];
    let getTicketPerAgentapi = [];
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };
    dashboarData["ticketType"] = data.type;
    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }

    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all" && data.type !== 'eservice'
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }
    let dashboardCountapi = {}
    if (["appointment", "eservice", "donation"].includes(data.type)) {
      dashboardCountapi = QueueApi("v2/dashboard/count", dashboarData, "post");
    }

    let dashboardCountapi2 = QueueApi(
      "v2/getDashboardCount",
      dashboarData,
      "post"
    );
    if (data.type === "instant" || !data.type) {
      dashboardCount1api = QueueApi(
        "dashboard/feedback/merchant",
        dashboarData,
        "post"
      );
      // getTicketPerAgentapi = QueueApi(
      //   "getTicketPerAgent",
      //   dashboarData,
      //   "post"
      // );
    }

    Promise.all([
      dashboardCountapi,
      dashboardCount1api,
      // getTicketPerAgentapi,
      dashboardCountapi2,
    ])
      .then((res) => {
        let [
          dashboardCount,
          dashboardCount1,
          // getTicketPerAgent,
          dashboardCount2,
        ] = res;
        if (dashboardCount2.status === api_status.success) {
          dispatch(getInvalidBranch());
          if (data.type === "instant" || !data.type) {
            dispatch({
              type: IS_AVG_SERVING_TIME_SHOW,
              payload: data?.service?.value !== "all" ? true : false,
            });
            dispatch({
              type: TOTAL_TICKETS,
              payload:
                (dashboardCount2?.data?.data?.ticketCompleted || 0) +
                (dashboardCount2?.data?.data?.ticketCancelled || 0) +
                (dashboardCount2?.data?.data?.ticketNoShow || 0) +
                (dashboardCount2?.data?.data?.ticketNotProcessed || 0),
            });
            dispatch({
              type: TOTAL_SERVE,
              payload: dashboardCount2?.data?.data?.ticketCompleted || 0,
            });
            dispatch({
              type: TOTAL_CANCEL,
              payload: dashboardCount2?.data?.data?.ticketCancelled || 0,
            });
            dispatch({
              type: TOTAL_NOSHOW,
              payload: dashboardCount2?.data?.data?.ticketNoShow || 0,
            });
            dispatch({
              type: TOTAL_NOTPROCESS,
              payload: dashboardCount2?.data?.data?.ticketNotProcessed || 0,
            });
            dispatch({
              type: AVG_CUSTOMER_RATING,
              payload: dashboardCount1?.data?.data?.avgFeedbackMerchant || 0,
            });
            dispatch({
              type: TOTAL_REVIEW,
              payload: dashboardCount1?.data?.data?.totalReview || 0,
            });
            // dispatch({
            //   type: AVG_TICKET_PER_AGENT,
            //   payload: getTicketPerAgent?.data?.data?.avgTicketPerAgent,
            // });
            dispatch({
              type: REBOOKTICKETS,
              payload: dashboardCount2?.data?.data?.ticketRebook || 0,
            });
            dispatch(setDashboardLoaderAction(false));
            dispatch(getTicketPerServiceData(data));
          }
          if (data.type === "appointment") {
            dispatch({
              type: TOTAL_APPOINTMENT,
              payload:
                (dashboardCount?.data?.data?.ticketScheduled || 0) +
                (dashboardCount?.data?.data?.ticketProcessing || 0) +
                (dashboardCount2?.data?.data?.ticketCompleted || 0) +
                (dashboardCount2?.data?.data?.ticketCancelled || 0) +
                (dashboardCount2?.data?.data?.ticketNoShow || 0) +
                (dashboardCount2?.data?.data?.ticketNotProcessed || 0),
            });
            dispatch({
              type: APPOINTMENT_SCHEDULED,
              payload: dashboardCount?.data?.data?.ticketScheduled || 0,
            });
            dispatch({
              type: APPOINTMENT_PROCESSING,
              payload: dashboardCount?.data?.data?.ticketProcessing || 0,
            });
            dispatch({
              type: APPOINTMENT_COMPLETED,
              payload: dashboardCount2?.data?.data?.ticketCompleted || 0,
            });
            dispatch({
              type: APPOINTMENT_CANCELLEDTICKET,
              payload: dashboardCount2?.data?.data?.ticketCancelled || 0,
            });
            dispatch({
              type: APPOINTMENT_NOSHOW,
              payload: dashboardCount2?.data?.data?.ticketNoShow || 0,
            });
            dispatch({
              type: APPOINTMENT_NOPROCESS,
              payload: dashboardCount2?.data?.data?.ticketNotProcessed || 0,
            });
            dispatch(setDashboardLoaderAction(false));
            // dispatch({
            //   type: APPOINTMENT_LOADING,
            //   payload: false,
            // });
          }
          if (data.type === "eservice") {
            dispatch({
              type: ESERVICE_NUMBEROFREQUESTS,
              payload:
                (dashboardCount2?.data?.data?.ticketCompleted || 0) +
                (dashboardCount?.data?.data?.ticketProcessing || 0) +
                (dashboardCount?.data?.data?.ticketScheduled || 0) +
                (dashboardCount2?.data?.data?.ticketCancelled || 0),
            });
            dispatch({
              type: ESERVICE_COMPLETED,
              payload: dashboardCount2?.data?.data?.ticketCompleted || 0,
            });
            dispatch({
              type: ESERVICE_PROCESSING,
              payload: dashboardCount?.data?.data?.ticketProcessing || 0,
            });
            dispatch({
              type: ESERVICE_SUBMITTED,
              payload: dashboardCount?.data?.data?.ticketScheduled || 0,
            });
            dispatch({
              type: ESERVICE_CANCELLED,
              payload: dashboardCount2?.data?.data?.ticketCancelled || 0,
            });
            dispatch(setDashboardLoaderAction(false));
            // dispatch({
            //   type: ESERVICE_LOADING,
            //   payload: false,
            // });
          }
          if (data.type === "donation") {
            dispatch({
              type: DONATION_NUMBEROFREQUESTS,
              payload:
                (dashboardCount2?.data?.data?.ticketCompleted || 0) +
                (dashboardCount?.data?.data?.ticketProcessing || 0) +
                (dashboardCount?.data?.data?.ticketScheduled || 0) +
                (dashboardCount2?.data?.data?.ticketCancelled || 0),
            });
            dispatch({
              type: DONATION_COMPLETED,
              payload: dashboardCount2?.data?.data?.ticketCompleted || 0,
            });
            dispatch({
              type: DONATION_PROCESSING,
              payload: dashboardCount?.data?.data?.ticketProcessing || 0,
            });
            dispatch({
              type: DONATION_SUBMITTED,
              payload: dashboardCount?.data?.data?.ticketScheduled || 0,
            });
            dispatch({
              type: DONATION_CANCELLED,
              payload: dashboardCount2?.data?.data?.ticketCancelled || 0,
            });
            dispatch(setDashboardLoaderAction(false));
          }
        }
      })
      .catch(() => {
        dispatch(setLoaderAction(false));
        // if (data.type === "appointment") {
        //   dispatch({
        //     type: APPOINTMENT_LOADING,
        //     payload: false,
        //   });
        // }
        // if (data.type === "eservice") {
        //   dispatch({
        //     type: ESERVICE_LOADING,
        //     payload: false,
        //   });
        // }
      });
  };
};
/**
 @name  getTicketPerDayData
 @file dashboard.js
 @description This method is used for get ticket per day data 
 */
export const getTicketPerDayData = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };
    let apiCall = true
    if (data.startDate && data.endDate && data.dateLable !== getTranslation("DASHBOARD.All")) {
      const startDate = moment(data.startDate);
      const endDate = moment(data.endDate);
      const daysDifference = endDate.diff(startDate, 'days');
      if (daysDifference + 1 > 31 && !data.isCsv) {
        apiCall = false;
      }
    }
    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }
    dashboarData["ticketType"] = data.type;
    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }
    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }

    if ((data.dateLable !== getTranslation("DASHBOARD.All") || data.isCsv) && apiCall) {
      const getTicketPerDay = await QueueApi("v2/dashboard/ticket/week", dashboarData, "post")
      if (getTicketPerDay.status === api_status.success) {

        if ((data.type === "instant" || !data.type) && data.isCsv) {
          return getTicketPerDay.data.data.ticketPerDay
        }
        if (data.type === "instant" || !data.type) {
          dispatch({
            type: TICKET_PER_DAY,
            payload: getTicketPerDay.data.data.ticketPerDay,
          });
        }
        if (data.type === "appointment") {
          dispatch({
            type: APPOINTMENT_PER_DAY,
            payload: getTicketPerDay.data.data.ticketPerDay,
          });
        }
      }
    }
    else {
      if (data.type === "instant" || !data.type) {
        dispatch({
          type: TICKET_PER_DAY,
          payload: [],
        });
      }

      if (data.type === "appointment") {
        dispatch({
          type: APPOINTMENT_PER_DAY,
          payload: [],
        });
      }
    }
  }
}

/**
 @name  getTicketPerServiceData
 @file dashboard.js
 @description This method is used for get ticket per service data 
 */
export const getTicketPerServiceData = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }

    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }
    let getTicketPerService = await QueueApi(
      "v2/getTicketPerService",
      dashboarData,
      "post"
    );
    if (store().service.service.service.service.length === 0) {
      await dispatch(getServiceAction({ name: "", type: "queue" }));
    }
    // Tickets per services chart
    let ticketPerServiceData = [];
    let servicesArr = await store().service.service.service.service;
    let servicesLength = servicesArr.length;
    let ticketPerServiceArr = [];
    let otherService = {
      serviceId: "other",
      serviceName: i18n.t("DASHBOARD.other"),
      count: 0,
    };

    if (getTicketPerService.status === api_status.success) {
      ticketPerServiceArr = getTicketPerService.data.data.ticketPerService;
      let ticketPerServiceLength = ticketPerServiceArr.length;

      // Handling specific service
      if (data.service.value !== "all") {
        let avgServeTime = ticketPerServiceArr.filter(
          (item) => item._id === data.service.value
        )[0];
        dispatch({
          type: AVG_SERVE_TIME,
          payload: avgServeTime?.avgServiceTime
            ? (avgServeTime.avgServiceTime / 60000).toFixed(2)
            : 0,
        });
      }

      // Match services and add unmatched services to "Other"
      for (let i = 0; i < ticketPerServiceLength; i++) {
        let matched = false;
        for (let j = 0; j < servicesLength; j++) {
          if (ticketPerServiceArr[i]._id === servicesArr[j].id) {
            let object = {
              count: ticketPerServiceArr[i].count,
              serviceId: servicesArr[j].id,
              serviceName: servicesArr[j].name,
            };
            ticketPerServiceData.push(object);
            matched = true;
            break;
          }
        }

        // If not matched, accumulate data under "Other" service
        if (!matched) {
          otherService.count += ticketPerServiceArr[i].count;
        }
      }

      // Add "Other" service to the data if there are unmatched services
      if (otherService.count > 0) {
        ticketPerServiceData.push(otherService);
      }

      let services = ticketPerServiceData;
      services.sort(function (a, b) {
        return b.count - a.count;
      });

      // Fetch services if needed
      if (store().service.service.service.service.length === 0) {
        await dispatch(getServiceAction({ name: "", type: "queue" }));
      }

      let getAllServices = await store().service.service.service.service;
      let totalTicket = await store().dashboard.dashboard.dashboard.totalTicket;

      // Process services for progress and other details
      for (let i = 0; i < services.length; i++) {
        let progress = (100 * services[i].count) / totalTicket;
        for (let j = 0; j < getAllServices.length; j++) {
          if (getAllServices[j].id === services[i].serviceId) {
            services[i].serviceName = getAllServices[j].name;
          }
        }
        let colorOpacity = 10 - i;
        colorOpacity = colorOpacity <= 0 ? 1 : colorOpacity;
        services[i]["color"] = `blue-light-${colorOpacity}`;
        services[i]["progress"] = progress;
      }

      dispatch({
        type: TICKET_PER_SERVICE,
        payload: ticketPerServiceData,
      });

      dispatch({
        type: MOST_USE_SERVICE,
        payload: services,
      });
    }
  };
};

/**
 @name  getTicketPerBranchData
 @file dashboard.js
 @description This method is used for get ticket per branch data 
 */
export const getTicketPerBranchData = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }
    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }

    QueueApi("v2/getTicketPerBranch", dashboarData, "post").then(
      (getTicketPerBranch) => {
        let ticketPerBranchData = [];
        let branchArr = store().branch.branch.branch.branch;
        let branchArrLength = branchArr.length;
        let ticketPerBranchArr = [];

        if (getTicketPerBranch.status === api_status.success) {
          ticketPerBranchArr = getTicketPerBranch?.data?.data?.ticketPerService;
          let ticketPerBranchArrLength = ticketPerBranchArr?.length;
          let otherBranch = {
            branchId: "other",
            branchName: i18n.t("DASHBOARD.other"),
            count: 0,
            avgServiceTime: 0,
            totalEntries: 0,
          };
          for (let i = 0; i < ticketPerBranchArrLength; i++) {
            let matched = false;
            for (let j = 0; j < branchArrLength; j++) {
              if (ticketPerBranchArr[i]._id === branchArr[j].id) {
                let object = {
                  branchId: branchArr[j].id,
                  branchName: branchArr[j].name,
                  count: ticketPerBranchArr[i].count,
                  avgServiceTime: ticketPerBranchArr[i].avgServiceTime / 60000, // Converting milliseconds to minutes
                };
                ticketPerBranchData.push(object);
                matched = true;
                break;
              }
            }

            // If no match is found, add the ticket to the "Other" branch
            if (!matched) {
              otherBranch.count += ticketPerBranchArr[i].count;
              otherBranch.avgServiceTime += ticketPerBranchArr[i].avgServiceTime;
              otherBranch.totalEntries++;
            }
          }

          // If there were unmatched tickets, add "Other" branch to the data
          if (otherBranch.count > 0) {
            // Calculate the average service time for "Other" branch
            otherBranch.avgServiceTime = otherBranch.avgServiceTime / otherBranch.totalEntries / 60000;
            ticketPerBranchData.push({
              branchId: otherBranch.branchId,
              branchName: otherBranch.branchName,
              count: otherBranch.count,
              avgServiceTime: otherBranch.avgServiceTime,
            });
          }
          dispatch({
            type: TICKET_PER_BRANCH,
            payload: ticketPerBranchData,
          });
        }
      }
    );
  };
};
/**
 @name  getTicketPerAgentData
 @file dashboard.js
 @description This method is used for get ticket per agent data 
 */
export const getTicketPerAgentData = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }
    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }

    QueueApi("v2/getTicketPerAgent", dashboarData, "post").then(
      (getTicketPerAgent) => {
        let user = store().user.user.user.user.filter(
          (item) => item.role === "agent"
        );
        let ticketsPerAgentData = [];
        let userArr = user;
        let userArrLength = userArr.length;
        let ticketsPerAgentArr = [];
        let otherAgent = {
          agentId: "other",
          agentName: i18n.t("DASHBOARD.other"),
          count: 0
        };

        if (getTicketPerAgent.status === api_status.success) {
          ticketsPerAgentArr = getTicketPerAgent.data.data.ticketsPerAgent;
          let ticketsPerAgentLength = ticketsPerAgentArr.length;

          for (let i = 0; i < ticketsPerAgentLength; i++) {
            let matched = false;

            for (let j = 0; j < userArrLength; j++) {
              if (ticketsPerAgentArr[i]._id === userArr[j].userID) {
                let object = {
                  count: ticketsPerAgentArr[i].count,
                  agentId: ticketsPerAgentArr[i]._id,
                  agentName: user[j].name
                };
                ticketsPerAgentData.push(object);
                matched = true;
                break;
              }
            }

            // If no match, add the ticket count to the "Other" agent
            if (!matched) {
              otherAgent.count += ticketsPerAgentArr[i].count;
            }
          }

          // If there were unmatched agents, add "Other" agent to the data
          if (otherAgent.count > 0) {
            ticketsPerAgentData.push({
              agentId: otherAgent.agentId,
              agentName: otherAgent.agentName,
              count: otherAgent.count
            });
          }

          dispatch({
            type: AVG_TICKET_PER_AGENT,
            payload: getTicketPerAgent?.data?.data?.avgTicketPerAgent,
          });

          dispatch({
            type: TICKET_PER_AGENT,
            payload: ticketsPerAgentData,
          });
        }
      }
    );
    // Tickets per agent
  };
};

/**
 @name  getServiceAverageRating
 @file dashboard.js
 @description This method is used for get service avg rating data 
 */
export const getServiceAverageRating = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }
    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }
    QueueApi("dashboard/feedback", dashboarData, "post").then(
      (dashboardFeedback) => {
        let dashboardFeedbackData = [];
        let servicesArr = store().service.service.service.service;
        let servicesArrLength = servicesArr.length;
        let dashboardFeedbackArr = [];
        if (dashboardFeedback.status === api_status.success) {
          dashboardFeedbackArr = dashboardFeedback.data.data.ticketPerService;
          let dashboardFeedbackArrLength = dashboardFeedbackArr.length;
          for (let i = 0; i < dashboardFeedbackArrLength; i++) {
            for (let j = 0; j < servicesArrLength; j++) {
              if (dashboardFeedbackArr[i]._id.serviceId === servicesArr[j].id) {
                let object = {
                  avg: dashboardFeedbackArr[i].avgQuantity,
                  count: dashboardFeedbackArr[i].count,
                  serviceId: dashboardFeedbackArr[i]._id.serviceId,
                  serviceName: servicesArr[j].name,
                };
                dashboardFeedbackData.push(object);
              }
            }
          }
          dispatch({
            type: SERVICE_AVG_RATING,
            payload: dashboardFeedbackData,
          });
        }
      }
    );
    // Service Vs Average rating
  };
};

/**
 @name  getBranchAverageRating
 @file dashboard.js
 @description This method is used for get branch avg rating data 
 */
export const getBranchAverageRating = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }

    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }
    QueueApi("dashboard/feedback/branch", dashboarData, "post").then(
      (branchAvgRating) => {
        // Branch Vs Average rating
        let branchGrandTotal = 0;
        let branchVsAvgRatingData = [];
        let branchArr = store().branch.branch.branch.branch;
        let branchArrLength = branchArr.length;
        let branchAvgRatingArr = [];
        let otherBranch = {
          branchId: "other",
          branchName: i18n.t("DASHBOARD.other"),
          avg: 0,
          count: 0,
          feedback: 0,
          totalEntries: 0 // Used to track the number of unmatched branches for averaging
        };

        if (branchAvgRating.status === api_status.success) {
          branchAvgRatingArr = branchAvgRating.data.data.ticketPerService;
          let branchAvgRatingArrLength = branchAvgRatingArr.length;

          for (let i = 0; i < branchAvgRatingArrLength; i++) {
            let matched = false;

            for (let j = 0; j < branchArrLength; j++) {
              if (branchAvgRatingArr[i]._id === branchArr[j].id) {
                branchGrandTotal += branchAvgRatingArr[i].avgQuantity;

                let object = {
                  avg: branchAvgRatingArr[i].avgQuantity,
                  branchId: branchArr[j].id,
                  branchName: branchArr[j].name,
                  count: branchAvgRatingArr[i].count,
                  feedback: branchAvgRatingArr[i].feedback
                    ? branchAvgRatingArr[i].feedback.length
                    : 0,
                };

                branchVsAvgRatingData.push(object);
                matched = true;
                break;
              }
            }

            // If no match, accumulate data in "Other" branch
            if (!matched) {
              otherBranch.avg += branchAvgRatingArr[i].avgQuantity;
              otherBranch.count += branchAvgRatingArr[i].count;
              otherBranch.feedback += branchAvgRatingArr[i].feedback
                ? branchAvgRatingArr[i].feedback.length
                : 0;
              otherBranch.totalEntries++;
            }
          }

          // If there were unmatched branches, calculate the average and add the "Other" branch
          if (otherBranch.count > 0) {
            otherBranch.avg = otherBranch.avg / otherBranch.totalEntries; // Calculate average
            branchGrandTotal += otherBranch.avg;

            branchVsAvgRatingData.push({
              branchId: otherBranch.branchId,
              branchName: otherBranch.branchName,
              avg: otherBranch.avg,
              count: otherBranch.count,
              feedback: otherBranch.feedback,
            });
          }

          let branchGrandTotalAvg = branchGrandTotal / branchVsAvgRatingData.length;

          dispatch({
            type: GRAND_AVG_PER_BRANCH,
            payload: branchGrandTotalAvg > 0 ? branchGrandTotalAvg : 0,
          });
          dispatch({
            type: BRANCH_AVG_RATING,
            payload: branchVsAvgRatingData,
          });
          dispatch(getBranchPerformance(data));
        }
      }
    );
  };
};
/**
 @name  getAgentAverageRating
 @file dashboard.js
 @description This method is used for get agent avg rating data 
 */
export const getAgentAverageRating = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }

    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }

    QueueApi("dashboard/feedback/agent", dashboarData, "post").then(
      (agentAvgRating) => {
        // Agent Vs Average rating
        let agentAvgRatingData = [];
        let grandTotal = 0;
        let userArr = store().user.user.user.user;
        let userArrLength = userArr.length;
        let agentAvgRatingArr = [];
        if (agentAvgRating.status === api_status.success) {
          agentAvgRatingArr = agentAvgRating.data.data.ticketPerService;
          let agentAvgRatingArrLength = agentAvgRatingArr.length;
          for (let i = 0; i < agentAvgRatingArrLength; i++) {
            for (let j = 0; j < userArrLength; j++) {
              if (agentAvgRatingArr[i]._id.agentId === userArr[j].userID) {
                grandTotal = grandTotal + agentAvgRatingArr[i].avgQuantity;
                let object = {
                  agentId: agentAvgRatingArr[i]._id.agentId,
                  avg: agentAvgRatingArr[i].avgQuantity,
                  agentName: userArr[j].name,
                };
                agentAvgRatingData.push(object);
              }
            }
          }
          let grandAgentAvg = grandTotal / agentAvgRatingData.length;
          dispatch({
            type: GRAND_AVG_PER_AGENT,
            payload: grandAgentAvg,
          });
          dispatch({
            type: AGENT_AVG_RATING,
            payload: agentAvgRatingData,
          });
        }
      }
    );
  };
};
/**
 @name  getBranchPerformance
 @file dashboard.js
 @description This method is used for get branch data 
 */
export const getBranchPerformance = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }

    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }

    let branchPerformance = await QueueApi(
      "v2/dashboard/branch/performance",
      dashboarData,
      "post"
    );
    let serviceID;
    if (data.service.value !== "all") {
      serviceID = [data.service.value];
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      serviceID = store().auth.login.user.serviceId;
    }
    let waitTime = await getWaitTime(serviceID);
    let branchRatings = await store().dashboard.dashboard.dashboard.avgFeedbackBranch;
    let branchPerformanceData = [];
    let branchArr = await store().branch.branch.branch.branch;
    let branchArrLength = branchArr.length;
    let branchPerformanceArr = [];
    if (branchPerformance.status === api_status.success) {
      branchPerformanceArr = branchPerformance?.data?.data?.ticketPerMerchant;
      let branchPerformanceArrLength = branchPerformanceArr?.length;
      let otherBranch = {
        branchId: "other",
        branchName: i18n.t("DASHBOARD.other"),
        avgRating: 0,
        avgServiceTime: 0,
        queueTime: 0,
        queueTimer: 1,
        serviceTime: 1,
        totalServed: 0,
        waitPeople: 0,
        totalEntries: 0 // Used to calculate average service time for "Other" branch
      };

      let otherBranchRating = branchRatings.filter((item) => item.branchId === 'other')[0];

      for (let i = 0; i < branchPerformanceArrLength; i++) {
        let matched = false;

        for (let j = 0; j < branchArrLength; j++) {
          if (branchPerformanceArr[i]._id.branchId === branchArr[j].id) {
            let rating = branchRatings.filter(
              (item) => item.branchId === branchArr[j].id
            )[0];
            let object = {
              avgRating: rating?.avg > 0 ? rating?.avg : 0,
              avgServiceTime: branchPerformanceArr[i].avgServiceTime / 60000, // Convert ms to minutes
              branchId: branchArr[j].id,
              branchName: branchArr[j].name,
              queueTime: branchPerformanceArr[i].avgQueueTime,
              queueTimer: 1,
              serviceTime: 1,
              totalServed: branchPerformanceArr[i].count,
              waitPeople: 0
            };
            branchPerformanceData.push(object);
            matched = true;
            break;
          }
        }

        // If no match is found, accumulate data under "Other" branch
        if (!matched) {
          otherBranch.avgRating = otherBranchRating?.avg > 0 ? otherBranchRating?.avg : 0;
          otherBranch.avgServiceTime += branchPerformanceArr[i].avgServiceTime;
          otherBranch.totalServed += branchPerformanceArr[i].count;
          otherBranch.queueTime += branchPerformanceArr[i].avgQueueTime;
          otherBranch.totalEntries++; // Track the number of unmatched entries
        }
      }

      // If there were unmatched branches, calculate averages and add "Other" branch
      if (otherBranch.totalServed > 0) {
        otherBranch.avgServiceTime = otherBranch.avgServiceTime / otherBranch.totalEntries / 60000; // Convert to minutes
        otherBranch.queueTime = otherBranch.queueTime / otherBranch.totalEntries;

        branchPerformanceData.push({
          branchId: otherBranch.branchId,
          branchName: otherBranch.branchName,
          avgRating: otherBranch.avgRating, // Can be 0 if no ratings for unmatched branches
          avgServiceTime: otherBranch.avgServiceTime,
          queueTime: otherBranch.queueTime,
          queueTimer: otherBranch.queueTimer,
          serviceTime: otherBranch.serviceTime,
          totalServed: otherBranch.totalServed,
          waitPeople: otherBranch.waitPeople
        });
      }
      const branchIds = branchPerformanceArr.map(item => item._id.branchId);
      let noDataBranch = [];
      if (data?.branch?.value !== "all") {
        noDataBranch = [data?.branch].filter(item => !branchIds.includes(item.value)).map(item => ({
          id: item.value,
          name: item.label
        }));
      } else {
        if (store().auth.login.user.role === GlobalVariable.manager) {
          const mangerBranch = branchArr.filter(item => store()?.auth?.login?.user?.branchId?.split(",").includes(item.id));
          noDataBranch = mangerBranch.filter(item => !branchIds.includes(item.id));
        } else {
          noDataBranch = branchArr.filter(item => !branchIds.includes(item.id));
        }
      }
      if (noDataBranch.length > 0) {
        for (let j = 0; j < noDataBranch.length; j++) {
          let object = {
            avgRating: 0,
            avgServiceTime: 0,
            branchId: noDataBranch[j].id,
            branchName: noDataBranch[j].name,
            queueTime: 0,
            queueTimer: 1,
            serviceTime: 1,
            totalServed: 0,
            waitPeople: 0,
          };
          branchPerformanceData.push(object);
        }
      }
      for (let i = 0; i < branchPerformanceData.length; i++) {
        for (let j = 0; j < waitTime.length; j++) {
          if (branchPerformanceData[i].branchId === waitTime[j]._id.branchId) {
            branchPerformanceData[i]["waitPeople"] = waitTime[j].count;
          } else {
            branchPerformanceData[i]["waitPeople"] = 0;
          }
        }
      }
      dispatch({
        type: BRANCH_PERFORMANCE,
        payload: branchPerformanceData,
      });
    }
  };
};

/**
 @name  getInvalidBranch
 @file dashboard.js
 @description This method is used for check branch 
 */
export const getInvalidBranch = (data) => {
  return async (dispatch, store) => {
    if (store().auth.login.user.merchantID) {
      let getDashboard = await api(
        `branch/invalid?merchantID=${store().auth.login.user.merchantID}`,
        {},
        "get"
      );

      if (getDashboard.status === api_status.success) {
        // dispatch(setLoaderAction(false))
        let isinValidBranch = false;
        if (getDashboard.data.data.length > 0) {
          isinValidBranch = true;
        }
        dispatch({
          type: SET_DASHBOARD_INVALID_BRANCH,
          payload: isinValidBranch,
        });
      }
    }
  };
};

export const getKPIAction = async (merchantID) => {
  let getKPI = await api(`kpi?merchantID=${merchantID}`, {}, "get");
  if (getKPI.status === api_status.success) {
    return getKPI;
  } else {
    toast.error(getKPI.message);
  }
};

/**
 * @name getWaitTime
 * @file dashboard.js
 * @requires branchId
 * @description return the value of waiting fileds of branch performance table in Dashboard
 */
export const getWaitTime = async (serviceId) => {
  let branch = store.getState().branch.branch.branch.branch;
  let branchId = branch.map((item) => item.id);
  let data = {
    branchId: branchId ? branchId.toString().split(",") : "",
  };
  if (serviceId) {
    data["serviceId"] = serviceId;
  }
  if (store.getState().auth.login.user.role === GlobalVariable.manager) {
    data["branchId"] = store.getState().auth.login.user.branchId.split(",");
  }
  let getWaitTime = await QueueApi(`dashboard/ticket/waittime`, data, "post");
  if (getWaitTime.status === api_status.success) {
    return getWaitTime.data.data.waitTime;
  } else {
    return [];
  }
};

/**
 @name  cleanDashboardReducer
 @file dashboard.js
 @description This method is used for set reducer state in intial  
 */
export const cleanDashboardReducer = () => {
  return async (dispatch, store) => {
    await dispatch({
      type: ADD_QUEUE,
      payload: { queue: [], pageNo: 0 },
    });
    await dispatch({
      type: GET_QUEUE_SERVICE,
      payload: [],
    });
    await dispatch({
      type: ADD_SERVICE,
      payload: { service: [], pageNo: 0 },
    });
    await dispatch({
      type: ADD_USER,
      payload: { user: [], pageNo: 0 },
    });
    await dispatch({
      type: ADD_BRANCH,
      payload: { branch: [], pageNo: 0 },
    });
    await dispatch({
      type: SET_SETTING,
      payload: { setting: {}, abuse: [] },
    });
    await dispatch({
      type: TOTAL_TICKETS,
      payload: 0,
    });
    await dispatch({
      type: TOTAL_SERVE,
      payload: 0,
    });
    await dispatch({
      type: TOTAL_CANCEL,
      payload: 0,
    });
    await dispatch({
      type: TOTAL_NOSHOW,
      payload: 0,
    });
    await dispatch({
      type: TOTAL_NOTPROCESS,
      payload: 0,
    });
    await dispatch({
      type: AVG_CUSTOMER_RATING,
      payload: 0,
    });
    await dispatch({
      type: TOTAL_REVIEW,
      payload: 0,
    });
    await dispatch({
      type: AVG_TICKET_PER_AGENT,
      payload: 0,
    });
    await dispatch({
      type: TICKET_PER_SERVICE,
      payload: [],
    });
    await dispatch({
      type: TICKET_PER_BRANCH,
      payload: [],
    });
    await dispatch({
      type: TICKET_PER_AGENT,
      payload: [],
    });
    await dispatch({
      type: BRANCH_PERFORMANCE,
      payload: [],
    });
    await dispatch({
      type: TICKET_PER_SERVICE,
      payload: [],
    });
    await dispatch({
      type: MOST_USE_SERVICE,
      payload: [],
    });
    await dispatch({
      type: TICKET_PER_DAY,
      payload: [],
    });
    await dispatch({
      type: SET_CURRENT_USER,
      payload: {},
    });
    await dispatch({
      type: SET_PUBLIC_KEY,
      payload: {},
    });
    await dispatch({
      type: TICKETS_BY_ORIGIN,
      payload: [],
    });
    dispatch(setAuthTokenAction(""));
    dispatch(setLoginUserAction({}));
    dispatch(getProfile(""));
    dispatch(setIsLoginAction(false));
  };
};

/**
 @name  getTicketByOrigintData
 @file dashboard.js 03/02/2023
 @description This method is used for get ticket by its origin (web,app,concierge) 
 */
export const getTicketByOriginData = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };
    let branchesID = store()?.dashboard.dashboard?.dashboard?.withoutDeleteBranch?.map(i => i.id);

    if (branchesID?.length > 0) {
      dashboarData["branches"] = branchesID;
    } else {
      dashboarData["branches"] = [];
    }

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.dateLable === getTranslation("DASHBOARD.All")) {
      const getCurrentWeekDays = () => {
        const weekStart = moment().startOf("week");

        const days = [];
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, "days"));
        }

        return days;
      };
      const weekDays = getCurrentWeekDays();
      dashboarData["weekStartDate"] = moment(weekDays[0]).local().valueOf();
      dashboarData["weekEndDate"] = moment(weekDays[6]).local().valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = [data.branch.value];
    }

    if (data.service.value !== "all") {
      dashboarData["serviceId"] = [data.service.value];
    }
    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId.split(",");
    }
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.service.value === "all"
    ) {
      dashboarData["serviceId"] = store().auth.login.user.serviceId;
    }

    QueueApi("v2/dashboard/getTicketsByOrigin", dashboarData, "post").then(
      (getTicketByOrigin) => {
        let branchTicketData = [];
        let ticketsByOriginArr = [];

        let otherBranch = {
          branchId: "other",
          name: i18n.t("DASHBOARD.other"),
          web: 0,
          concierge: 0,
          app: 0
        };

        if (getTicketByOrigin.status === api_status.success) {
          ticketsByOriginArr = getTicketByOrigin.data.data;
          let branchDetails = store().branch.branch.branch?.branch;

          for (let i = 0; i < ticketsByOriginArr.length; i++) {
            let findBranch = branchDetails.find(
              (branch) => branch.id === ticketsByOriginArr[i].branchId
            );

            if (findBranch) {
              branchTicketData.push({
                ...ticketsByOriginArr[i],
                name: findBranch?.name,
              });
            } else {
              // Accumulate unmatched data in "Other"
              otherBranch.web += ticketsByOriginArr[i].web;
              otherBranch.concierge += ticketsByOriginArr[i].concierge;
              otherBranch.app += ticketsByOriginArr[i].app;
            }
          }

          // Add "Other" branch data if there are any unmatched entries
          if (otherBranch.web > 0 || otherBranch.concierge > 0 || otherBranch.app > 0) {
            branchTicketData.push(otherBranch);
          }

          dispatch({
            type: TICKETS_BY_ORIGIN,
            payload: branchTicketData,
          });
        }
      }
    );
  };
};
/**
 @name  ticketHistoryTable
 @file dashboard.js
 @description This method is used for get ticket history Table Data
 */
export const ticketHistoryTable = async (data) => {
  if (data.dateLable === getTranslation("DASHBOARD.All")) {
    delete data.startDate;
  }
  if (data.branchId) {
    data.branchId = data.branchId.value;
  }
  if (data.status) {
    data.status = data.status.value;
  }
  if (data.processedByUserId) {
    data.processedByUserId = data.processedByUserId.value;
  }
  if (data.branchId === "all") {
    delete data.branchId;
  }
  if (data.status === "all") {
    delete data.status;
  }
  if (data.processedByUserId === "all") {
    delete data.processedByUserId;
  }
  if (data.startDate) {
    data.startDate = data.startDate.valueOf();
  }
  if (data.endDate) {
    data.endDate = data.endDate.valueOf();
  }
  delete data.dateLable;
  let ticketHistoryResponse = await QueueApi(`past-tickets`, data, "post");
  if (ticketHistoryResponse.status === api_status.success) {
    return ticketHistoryResponse.data.data;
  } else {
    toast.error(ticketHistoryResponse.message.message);
  }
};
/**
 @name  upcommingTickets
 @file dashboard.js
 @description This method is used for get Upcoming Tickets Table Data
 */
export const upcommingTickets = async (data) => {
  let {
    ticketType,
    startDate,
    endDate,
    serviceId,
    processedByUserId,
    status,
    page,
    limit,
    merchantId,
    search,
    dateLable,
    branchId,
    completStartDate,
    completEndDate,
    appointmentStartDate,
    appointmentEndDate,
    appointmentLabel,
    bookingLabel,
    sort,
  } = data;
  const role = store.getState().auth.login.user.role;
  const managerService = store.getState().auth.login.user.serviceId;
  let sendToData = {
    ticketType,
    merchantId,
    page,
    limit,
  };
  if (sort) {
    sendToData["sort"] = sort.value;
  }
  if (dateLable !== getTranslation("GLOBLE_MESSAGE.All") && startDate && endDate) {
    sendToData["startDate"] = startDate.valueOf();
    sendToData["endDate"] = endDate.valueOf();
  }
  if (bookingLabel !== getTranslation("GLOBLE_MESSAGE.All") && completStartDate && completEndDate) {
    sendToData["completStartDate"] = completStartDate.valueOf();
    sendToData["completEndDate"] = completEndDate.valueOf();
  }
  if (appointmentLabel !== getTranslation("GLOBLE_MESSAGE.All") && appointmentStartDate && appointmentEndDate) {
    sendToData["appointmentStartDate"] = appointmentStartDate.valueOf();
    sendToData["appointmentEndDate"] = appointmentEndDate.valueOf();
  }

  if (serviceId) {
    if (serviceId.value !== "all") {
      sendToData["serviceId"] = serviceId.value;
    }
    if (role === GlobalVariable.manager && serviceId.value === "all") {
      sendToData["serviceId"] = managerService;
    }
  }
  if (processedByUserId && processedByUserId.value !== "all") {
    sendToData["processedByUserId"] = processedByUserId.value;
  }
  if (status && status.value !== "all") {
    sendToData["status"] = status.value;
  }
  if (branchId && branchId.value !== "all") {
    sendToData["branchId"] = branchId.value;
  }
  if (search) {
    sendToData["searchParam"] = search;
  }
  let response = await QueueApi(`upcomming-tickets`, sendToData, "post");
  if (response.status === api_status.success) {
    return response.data.data;
  } else {
    toast.error(response.message.message);
  }
};

export const getTicketTrail = (id) => {
  return async (dispatch, store) => {
    dispatch(setTicketLoader(true));
    let ticketRes = await QueueApi(`eservice/ticket-trail?id=${id}`, {}, "get");
    if (ticketRes.status === api_status.success) {
      dispatch({
        type: SET_ESERVICE_TRAIL,
        payload: ticketRes.data.data,
      });
      dispatch(setTicketLoader(false));
    } else {
      dispatch(setTicketLoader(false));
    }
  };
};

export const setTicketLoader = (value) => {
  return {
    type: SET_ESERVICE_LOADER,
    payload: value,
  };
};

export const getPeakHourData = (data) => {
  return async (dispatch, store) => {
    let dashboarData = {
      merchantId: store().auth.login.user.merchantID,
    };

    if (data.dateLable !== getTranslation("DASHBOARD.All")) {
      dashboarData["startDate"] = moment(data.startDate)
        .startOf("day")
        .valueOf();
      dashboarData["endDate"] = moment(data.endDate).endOf("day").valueOf();
    }

    if (data.branch.value !== "all") {
      dashboarData["branchId"] = data.branch.value;
    }

    if (data.agents.value !== "all") {
      dashboarData["processedByUserId"] = data.agents.value;
    }
    dashboarData["timeZone"] = data.timeZone;
    // this need for manager panel
    if (
      store().auth.login.user.role === GlobalVariable.manager &&
      data.branch.value === "all"
    ) {
      dashboarData["branchId"] = store().auth.login.user.branchId;
    }

    const getPeakHours = await QueueApi("dashboard/peak-hours", dashboarData, "post")
    if (getPeakHours.status === api_status.success) {
      if (getPeakHours.data.data.length === 0) {
        dispatch({
          type: APPOINTMENT_PEAK_HOURS,
          payload: [],
        });
      }
      if (getPeakHours.data.data.length > 0) {
        const timeData = getStartTimeAndEndTime(getPeakHours?.data?.data)
        const updateData = getproperData(timeData, getPeakHours?.data?.data)
        const filteredData = updateData.filter(item => item[0].day);
        if (filteredData) {
          dispatch({
            type: APPOINTMENT_PEAK_HOURS,
            payload: filteredData,
          });
        }
      }
    }
  };
};

/**
 @name  getWorkProfileData
 @file dashboard.js
 @description for get WorkProfile Data
 */
export const getWorkProfileData = async (id, branchID) => {
  let getWorkProfile = [];
  if (branchID === "all") {
    getWorkProfile = await api(
      `workprofile/assigned?merchantId=${id}`,
      {},
      "get"
    );
  } else {
    getWorkProfile = await api(
      `workprofile/assigned?merchantId=${id}&branchId=${branchID}`,
      {},
      "get"
    );
  }

  if (getWorkProfile.status === api_status.success) {
    return getWorkProfile.data.data
  }
};

/**
 @name  getEngagementTrafficeData
 @description This method is used for get Engagement Traffic Data in Donation Dashboard
 */
export const getEngagementTrafficeData = (data) => {
  return async (dispatch, store) => {
    try {
      let sendToData = {
        merchantId: [store().auth.login.user.merchantID]
      }

      if (data.dateLable !== getTranslation("DASHBOARD.All")) {
        if (data.startDate && data.endDate) {
          sendToData["startDate"] = data.startDate.valueOf()
        }
        if (data.endDate) {
          sendToData["endDate"] = data.endDate.valueOf()
        }
      }

      const resData = await api("click-log/counts", sendToData, 'post')
      if (resData.status === api_status.success) {
        dispatch({
          type: DONATION_ENGAGEMENT,
          payload: resData.data.data,
        });
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };
};
/**
 @name  getEngagementTrafficeCSV
 @description This method is used for get Engagement Traffic Data in Donation Dashboard
 */
export const getEngagementTrafficeCSV = (data) => {
  return async (dispatch, store) => {
    try {
      let countData = store().dashboard?.dashboard?.donationDashboard?.engagementData

      let sendToData = {
        merchantId: [store().auth.login.user.merchantID]
      }
      if (data.dateLable !== getTranslation("DASHBOARD.All")) {
        if (data.startDate && data.endDate) {
          sendToData["startDate"] = data.startDate.valueOf()
        }
        if (data.endDate) {
          sendToData["endDate"] = data.endDate.valueOf()
        }
      }

      const resData = await api("click-log/details", sendToData, 'post')
      if (resData.status === api_status.success) {
        const countDataArray = Object.keys(countData).map((key) => {
          return {
            chanelCount: key,
            clicks: countData[key],
          };
        });

        const maxIndex = Math.max(
          countDataArray.length,
          resData.data.data.length
        );

        const combinedData = Array.from({ length: maxIndex }, (_, index) => {
          const item = resData.data.data[index] || {};
          const countDataItem = countDataArray[index] || {};

          const combinedItem = {
            ...(item.email && { email: item.email }),
            ...(item.phone && { phone: item.phone }),
            ...(item.name && { name: item.name }),
            ...(item.date && { date: item.date }),
            ...(item.channel && { channel: item.channel }),
            ...countDataItem,
          };

          return combinedItem;
        });
        return combinedData;
      }
    } catch (error) {
      console.log(error);
    }
  };
};
