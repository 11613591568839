export const SET_LOADER = "SET_LOADER";
export const SET_DASHBOARD_LOADER = "SET_DASHBOARD_LOADER";
export const SET_IS_AUTH = "SET_IS_AUTH";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_REFRESHTOKEN = "SET_USER_REFRESHTOKEN";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_PERMISSION = "USER_PERMISSION";

export const IS_ADD_USER_AVAIABLE = "IS_ADD_USER_AVAIABLE";
// category Types
export const ADD_CATEGORY = "ADD_CATEGORY";

export const ADD_SERVICE = "ADD_SERVICE";
export const ADD_USER = "ADD_USER";
export const ADD_AGENT = "ADD_AGENT";

export const ADD_BRANCH = "ADD_BRANCH";
export const BRANCH_QUEUE_VALID_STATUS = "BRANCH_QUEUE_VALID_STATUS";
export const BRANCH_SERVICE_VALID_STATUS = "BRANCH_SERVICE_VALID_STATUS";
export const BRANCH_AGENT_VALID_STATUS = "BRANCH_AGENT_VALID_STATUS";
export const BRANCH_COUNTER_VALID_STATUS = "BRANCH_COUNTER_VALID_STATUS";
export const ADD_QUEUE = "ADD_QUEUE";
export const GET_QUEUE_SERVICE = "GET_QUEUE_SERVICE";

export const SET_FORGOTPASSWORD_USER = "SET_FORGOTPASSWORD_USER";

export const SET_SETTING = "SET_SETTING";
export const SET_ABUSE = "SET_ABUSE";

export const SET_DASHBOARD = "SET_DASHBOARD";
export const SET_DASHBOARD_INVALID_BRANCH = "SET_DASHBOARD_INVALID_BRANCH";

export const SET_USER_CREATE_AVAILABLE = "SET_USER_CREATE_AVAILABLE";
export const SET_INACTIVE_USER = "SET_INACTIVE_USER";

export const SET_BREDCUME = "SET_BREDCUME";
export const ADD_FEEDBACK = "ADD_FEEDBACK";
export const TOTAL_FEEDBACK_COUNT = "TOTAL_FEEDBACK_COUNT";
export const SET_COUNTS = "SET_COUNTS";
export const TOTAL_TICKETS = "TOTAL_TICKETS";
export const TOTAL_SERVE = "TOTAL_SERVE";
export const TOTAL_CANCEL = "TOTAL_CANCEL";
export const TOTAL_NOSHOW = "TOTAL_NOSHOW";
export const TOTAL_NOTPROCESS = "TOTAL_NOTPROCESS";
export const TICKET_PER_SERVICE = "TICKET_PER_SERVICE";
export const TICKET_PER_BRANCH = "TICKET_PER_BRANCH";
export const TICKET_PER_AGENT = "TICKET_PER_AGENT";
export const SERVICE_AVG_RATING = "SERVICE_AVG_RATING";
export const BRANCH_AVG_RATING = "BRANCH_AVG_RATING";
export const BRANCH_PERFORMANCE = "BRANCH_PERFORMANCE";
export const TICKET_PER_DAY = "TICKET_PER_DAY";
export const AVG_CUSTOMER_RATING = "AVG_CUSTOMER_RATING";
export const TOTAL_REVIEW = "TOTAL_REVIEW";
export const MOST_USE_SERVICE = "MOST_USE_SERVICE";
export const AVG_TICKET_PER_AGENT = "AVG_TICKET_PER_AGENT";
export const AGENT_AVG_RATING = "AGENT_AVG_RATING";
export const GRAND_AVG_PER_AGENT = "GRAND_AVG_PER_AGENT";
export const GRAND_AVG_PER_BRANCH = "GRAND_AVG_PER_BRANCH";
export const IS_AVG_SERVING_TIME_SHOW = "IS_AVG_SERVING_TIME_SHOW";
export const AVG_SERVE_TIME = "AVG_SERVE_TIME";
export const ADD_MANAGER_SERVICE = "ADD_MANAGER_SERVICE";

export const SET_PUBLIC_KEY = "SET_PUBLIC_KEY";
export const IS_PASSWORD_CHANGE = "IS_PASSWORD_CHANGE";
export const TICKETS_BY_ORIGIN = "TICKETS_BY_ORIGIN";
export const REBOOKTICKETS = "REBOOKTICKETS";

export const SET_LANG = "SET_LANG"
export const CHANGE_DIRECTION = "CHANGE_DIRECTION"

export const SET_SEARCH="SET_SEARCH"

export const SERVICE_TYPE_DROPDOWN ="SERVICE_TYPE_DROPDOWN"
export const BRANCH_FILE_UPLOAD_STATUS ="BRANCH_FILE_UPLOAD_STATUS"
export const SET_CATEGORY_TYPE ="SET_CATEGORY_TYPE"

export const TOTAL_APPOINTMENT="TOTAL_APPOINTMENT"
export const APPOINTMENT_SCHEDULED="APPOINTMENT_SCHEDULED"
export const APPOINTMENT_PROCESSING="APPOINTMENT_PROCESSING"
export const APPOINTMENT_COMPLETED="APPOINTMENT_COMPLETED"
export const APPOINTMENT_CANCELLEDTICKET="APPOINTMENT_CANCELLEDTICKET"
export const APPOINTMENT_NOSHOW="APPOINTMENT_NOSHOW"
export const APPOINTMENT_NOPROCESS="APPOINTMENT_NOPROCESS"
export const APPOINTMENT_PER_DAY="APPOINTMENT_PER_DAY"
export const APPOINTMENT_LOADING="APPOINTMENT_LOADING"
export const APPOINTMENT_PEAK_HOURS = "APPOINTMENT_PEAK_HOURS";

export const ESERVICE_NUMBEROFREQUESTS = "ESERVICE_NUMBEROFREQUESTS";
export const ESERVICE_SUBMITTED = "ESERVICE_SUBMITTED";
export const ESERVICE_PROCESSING = "ESERVICE_PROCESSING";
export const ESERVICE_COMPLETED = "ESERVICE_COMPLETED";
export const ESERVICE_CANCELLED = "ESERVICE_CANCELLED";
export const ESERVICE_LOADING = "ESERVICE_LOADING";
export const SET_ESERVICE_TRAIL = "SET_ESERVICE_TRAIL";
export const SET_ESERVICE_LOADER = "SET_ESERVICE_LOADER";


export const DONATION_NUMBEROFREQUESTS = "DONATION_NUMBEROFREQUESTS";
export const DONATION_SUBMITTED = "DONATION_SUBMITTED";
export const DONATION_PROCESSING = "DONATION_PROCESSING";
export const DONATION_COMPLETED = "DONATION_COMPLETED";
export const DONATION_CANCELLED = "DONATION_CANCELLED";
export const DONATION_ENGAGEMENT = "DONATION_ENGAGEMENT";
export const WITHOUT_DELETE_BRANCH = "WITHOUT_DELETE_BRANCH";