export class GlobalVariable {
  static apiUrl = {
    // local url
    // mainUrl: 'http://localhost:1337/',
    // mainUrl: 'http://15.206.7.200/dooree-api/',
    // queueApiUrl: 'http://15.206.7.200/dooree-queue-api/'
    // test server
    // mainUrl: "https://api.test.balador.io/user/",
    // queueApiUrl: "https://api.test.balador.io/queue/",
    // notificationUrl: "https://api.test.balador.io/notification/",
    // reportUrl: "https://api.test.balador.io/report/",

    //staging
    // mainUrl: "https://api.staging.balador.io/user/",
    // queueApiUrl: "https://api.staging.balador.io/queue/",
    // notificationUrl: "https://api.staging.balador.io/notification/",
    // reportUrl: "https://api.staging.balador.io/report/",

    // /// live
    mainUrl: "https://api.balador.io/user/",
    queueApiUrl: "https://api.balador.io/queue/",
    notificationUrl: "https://api.balador.io/notification/",
    reportUrl: "https://api.balador.io/report/",
    socketUrl: "https://api.balador.io",
  };
  static customerApiUrl = "https://app.balador.io/user/branch"; // change balador-customer to user in live
  static customerEventUrl = "https://app.balador.io/user/bookticket"; // change balador-customer to user in live
  static mapApiKey = "AIzaSyABo4UtUUxFmZhbAcUEQRmUqUPFqgCeKHA"; //this updates when user login
  static awsConfig = {
    accessKeyId: "AKIA3YUPHMO2SOMD4BN7",
    secretAccessKey: "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",
    Bucket: "dooree-test",
    region: "me-south-1",
    signatureVersion: "v4",
  };

  static apiResponseStatus = {
    success: 200,
    error: 400,
    unAuthorized: 403,
    serverError: 500,
  };

  static localStoreName = "merchant-dooree";
  static loginId = "merchant-dooree-login-id";
  static merchantToken = "merchant-token";
  static isPChanged = "is-p-changed";

  static windowGlobelPathFull = "/merchant/";
  static windowGlobelPath = "/merchant";
  static windowGlobelPathWithLogin = "/merchant/login";
  static windowGlobelPathLogin = "/login";

  static windowConfirmPassword = "/forgot-password/confirm";
  static windowPathWithConfirmPassword = "/merchant/forgot-password/confirm";

  static user = "user";
  static all = "all";
  static manager = "manager";
  static merchant = "merchant";
  static merchantUser = "merchant-user";
  static regionalmanager = "regionalmanager";
  static branchManager = "Branch Manager";
  static regionalManager = "Regional Manager";
  static agent = "agent";
  static concierge = "concierge";
  static userEmail = "user-email";
  static secreteKey = "2ebZoWGNHnbWu82AuQlvBj3fTngPjUSJ";
  static sentryDSN = "https://8a0393ab43cb4bf99d98bfcdb131428a@o1395578.ingest.sentry.io/6738162";
  static appointmentAgent = "appointmentagent";
  static virtualAgent = "virtual-agent";
  static eserviceagent = "eserviceagent";
  static donationagent = "donationagent";
  static roles = {
    agent: "Queue Agent",
    "virtual-agent": "Virtual Agent",
    concierge: "Concierge",
    manager: "Manager",
    "merchant-user": "Merchant User",
    "appointmentagent": "Appointment Agent",
    "eserviceagent": "E-service Agent",
  }
  static localLang = "balador-merchant-local-lang-";
  static english = 'en';
  static arabic = 'ar';

  static ETicketStatus = {
    todo: "",
    processed: "processed",
    completed: "completed",
    cancelled: "cancelled"
  }
  static hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  static errorMessage = "Oops! Something went wrong on our end. Please try again later or contact our support team."
}